<template>
  <div class="app-container inspection-detail-component">
    <InspectionDetail
      :inspectionJobId="inspectionJobId"
      :key="inspectionJobId"
    />
  </div>
</template>

<script>
import InspectionDetail from "./inspectionDetail.vue";
export default {
  name: "inspectionDetailComponent",
  components: {
    InspectionDetail,
  },
  data() {
    return {
      inspectionJobId: null,
    };
  },
  created() {
    const { inspectionJobId } = this.$route.query;
    this.inspectionJobId = inspectionJobId;
  },
};
</script>

<style lang="less" scoped>
.inspection-detail-component {
  padding: 0;
  background: #f1f6fd;
}
</style>